
export const reqGetListReferral = async(
  axios, {
    page,
    perpage,
    q,
    orderBy,
    order,
  }) => {
  return axios.get('/v1/referral/list-referral', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      q: q || null,
      orderBy: orderBy || null, // total_poin | total_referral
      order: order || null, // asc | desc
    },
  })
}

export const reqGetListReferralGifts = async(axios, { page, perpage, q }) => {
  return axios.get('/v1/referral/gifts', {
    params: {
      page: page || 1,
      perpage: perpage || 10,
      q: q || null,
    },
  })
}

export const reqGetReferral = async(axios) => {
  // ref reqGetListReferral: https://gitlab.com/web-apps-dietela/backend/-/merge_requests/350
  return axios.get('/v1/referral/')
}